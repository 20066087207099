/* eslint-disable */
import {UpdateTaskInput as UpdateTaskInputOrig} from '../types/globalTypes'
import {UpdateReceivedWebformInput as UpdateReceivedWebformInputOrig} from '../types/globalTypes'
import {UpdateReadyForAuditInput as UpdateReadyForAuditInputOrig} from '../types/globalTypes'
import {UpdatePersonInput as UpdatePersonInputOrig} from '../types/globalTypes'
import {UpdateEsignSessionInput as UpdateEsignSessionInputOrig} from '../types/globalTypes'
import {UpdateEditableFileInput as UpdateEditableFileInputOrig} from '../types/globalTypes'
import {UpdateDocumentInput as UpdateDocumentInputOrig} from '../types/globalTypes'
import {UpdateApplicationStatusInput as UpdateApplicationStatusInputOrig} from '../types/globalTypes'
import {UpdateApplicationInput as UpdateApplicationInputOrig} from '../types/globalTypes'
import {UpdateAccountStatusInput as UpdateAccountStatusInputOrig} from '../types/globalTypes'
import {UpdateAccountInput as UpdateAccountInputOrig} from '../types/globalTypes'
import {TriageInput as TriageInputOrig} from '../types/globalTypes'
import {TaskInput as TaskInputOrig} from '../types/globalTypes'
import {RelationshipInput as RelationshipInputOrig} from '../types/globalTypes'
import {DeserializedFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {parseFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {serializeFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {RelatedPersonInput as RelatedPersonInputOrig} from '../types/globalTypes'
import {PhoneNumberInput as PhoneNumberInputOrig} from '../types/globalTypes'
import {PersonInput as PersonInputOrig} from '../types/globalTypes'
import {IncomeSourceInput as IncomeSourceInputOrig} from '../types/globalTypes'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import {EligibilityDeterminationInput as EligibilityDeterminationInputOrig} from '../types/globalTypes'
import {EditableFileInput as EditableFileInputOrig} from '../types/globalTypes'
import {DocumentInput as DocumentInputOrig} from '../types/globalTypes'
import {DocumentFileInput as DocumentFileInputOrig} from '../types/globalTypes'
import {DeductionInput as DeductionInputOrig} from '../types/globalTypes'
import {DateRangeInput as DateRangeInputOrig} from '../types/globalTypes'
import {BenefitOutcomeInput as BenefitOutcomeInputOrig} from '../types/globalTypes'
import {AuditDataInput as AuditDataInputOrig} from '../types/globalTypes'
import {AssetInput as AssetInputOrig} from '../types/globalTypes'
import {deserializeMcdSubtype} from 'graphql/converters'
import {serializeMcdSubtype} from 'graphql/converters'
import {deserializeSlideType} from 'graphql/converters'
import {serializeSlideType} from 'graphql/converters'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {ApplicationInput as ApplicationInputOrig} from '../types/globalTypes'
import {ApplicationHouseholdMembersInput as ApplicationHouseholdMembersInputOrig} from '../types/globalTypes'
import {ApplicationHouseholdMemberInput as ApplicationHouseholdMemberInputOrig} from '../types/globalTypes'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {AccountInput as AccountInputOrig} from '../types/globalTypes'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
//==============================================================
// START Enums and Input Objects
//==============================================================
export enum Benefit {
  charityCare = 'charityCare',
  medicaid = 'medicaid',
  monitor = 'monitor',
  ryanWhite = 'ryanWhite',
  slide = 'slide',
}
export enum EditableFileStatus {
  merging = 'merging',
  new = 'new',
  outForSigning = 'outForSigning',
  returned = 'returned',
}
export enum InboundInsuranceOnDateOfService {
  charityCare = 'charityCare',
  insured = 'insured',
  selfPay = 'selfPay',
  uninsured = 'uninsured',
}
export enum InboundType {
  hospitalVisit = 'hospitalVisit',
  office = 'office',
  scheduled = 'scheduled',
}
export enum McdSubtype {
  CAK = 'CAK',
  NJSPCP = 'NJSPCP',
  PECA = 'PECA',
  PEPW = 'PEPW',
}
export enum McdType {
  AEMA = 'AEMA',
  Full = 'Full',
  Newborn = 'Newborn',
  PE = 'PE',
}
export enum NjmmisCheckResult {
  check_failed = 'check_failed',
  has_current_coverage = 'has_current_coverage',
  no_current_coverage = 'no_current_coverage',
  not_checked = 'not_checked',
}
export enum NjmmisCheckStatus {
  checkFailed = 'checkFailed',
  hasCurrentCoverage = 'hasCurrentCoverage',
  noCurrentCoverage = 'noCurrentCoverage',
  pending = 'pending',
}
export enum ServiceType {
  ED = 'ED',
  Inpatient = 'Inpatient',
  LD = 'LD',
  Outpatient = 'Outpatient',
}
export enum SlideType {
  Insured = 'Insured',
  ReducedFee = 'ReducedFee',
  Uninsured = 'Uninsured',
}
/**
 * Attributes for creating a new account
 */

export interface AccountInput {
  hospitalAccountId: string
  dateOfService: Date
  timeOfService?: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location?: string | null
  insuranceType: string
  status: string
  njmmisCheck?: string | null
  previousInsurance?: string | null
  isScheduled: boolean
}
/**
 * Attributes for creating a new application household member
 */

export const deserializeAccountInput = (
  serialized: AccountInputOrig
): AccountInput => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
})
export const serializeAccountInput = (
  deserialized: AccountInput
): AccountInputOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
})
export interface ApplicationHouseholdMemberInput {
  personId: string
  wantsCoverage?: boolean | null
}
/**
 * Attributes for creating a new set of application household members
 */

export const deserializeApplicationHouseholdMemberInput = (
  serialized: ApplicationHouseholdMemberInputOrig
): ApplicationHouseholdMemberInput => ({...serialized})
export const serializeApplicationHouseholdMemberInput = (
  deserialized: ApplicationHouseholdMemberInput
): ApplicationHouseholdMemberInputOrig => ({...deserialized})
export interface ApplicationHouseholdMembersInput {
  applicationId: string
  householdMembers: ApplicationHouseholdMemberInput[]
}
/**
 * Attributes for creating a new application
 */

export const deserializeApplicationHouseholdMembersInput = (
  serialized: ApplicationHouseholdMembersInputOrig
): ApplicationHouseholdMembersInput => ({
  ...serialized,
  householdMembers: serialized.householdMembers.map(
    deserializeApplicationHouseholdMemberInput
  ),
})
export const serializeApplicationHouseholdMembersInput = (
  deserialized: ApplicationHouseholdMembersInput
): ApplicationHouseholdMembersInputOrig => ({
  ...deserialized,
  householdMembers: deserialized.householdMembers.map(
    serializeApplicationHouseholdMemberInput
  ),
})
export interface ApplicationInput {
  id?: string | null
  personId: string
  benefit: string
  status: string
  facility?: string | null
  mcdType?: string | null
  initialDateOfService?: Date | null
  assignedToId: string
  charityCareType?: string | null
  slideType?: string | null
  mcdSubtype?: string | null
}
/**
 * Attributes for creating or updating an asset
 */

export const deserializeApplicationInput = (
  serialized: ApplicationInputOrig
): ApplicationInput => ({
  ...serialized,
  mcdType:
    serialized.mcdType != null
      ? deserializeMcdType(serialized.mcdType)
      : serialized.mcdType,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  slideType:
    serialized.slideType != null
      ? deserializeSlideType(serialized.slideType)
      : serialized.slideType,
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
})
export const serializeApplicationInput = (
  deserialized: ApplicationInput
): ApplicationInputOrig => ({
  ...deserialized,
  mcdType:
    deserialized.mcdType != null
      ? serializeMcdType(deserialized.mcdType)
      : deserialized.mcdType,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  slideType:
    deserialized.slideType != null
      ? serializeSlideType(deserialized.slideType)
      : deserialized.slideType,
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
})
export interface AssetInput {
  id?: string | null
  assetType?: string | null
  amount?: number | null
}
export const deserializeAssetInput = (
  serialized: AssetInputOrig
): AssetInput => ({...serialized})
export const serializeAssetInput = (
  deserialized: AssetInput
): AssetInputOrig => ({...deserialized})
export interface AuditDataInput {
  auditExternalId: string
  accountId: string
  applicationId: string
  editableFileId: string
}
/**
 * Attributes for creating or updating a benefit outcome
 */

export const deserializeAuditDataInput = (
  serialized: AuditDataInputOrig
): AuditDataInput => ({...serialized})
export const serializeAuditDataInput = (
  deserialized: AuditDataInput
): AuditDataInputOrig => ({...deserialized})
export interface BenefitOutcomeInput {
  id?: string | null
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate?: Date | null
  deniedIneligibleReason?: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName?: string | null
  policyId?: string | null
  notes?: string | null
  personId: string
  applicationId?: string | null
  patientResponsibility?: number | null
}
export const deserializeBenefitOutcomeInput = (
  serialized: BenefitOutcomeInputOrig
): BenefitOutcomeInput => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
})
export const serializeBenefitOutcomeInput = (
  deserialized: BenefitOutcomeInput
): BenefitOutcomeInputOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
})
export interface DateRangeInput {
  start: Date
  end: Date
}
/**
 * Attributes for creating or updating a deduction
 */

export const deserializeDateRangeInput = (
  serialized: DateRangeInputOrig
): DateRangeInput => ({
  ...serialized,
  start: parseDate(serialized.start),
  end: parseDate(serialized.end),
})
export const serializeDateRangeInput = (
  deserialized: DateRangeInput
): DateRangeInputOrig => ({
  ...deserialized,
  start: formatISODate(deserialized.start),
  end: formatISODate(deserialized.end),
})
export interface DeductionInput {
  id?: string | null
  deductionType?: string | null
  startMonth?: string | null
  startYear?: string | null
  endMonth?: string | null
  endYear?: string | null
  amount?: number | null
  deductionFrequency?: string | null
}
/**
 * Attributes for creating or updating a document file
 */

export const deserializeDeductionInput = (
  serialized: DeductionInputOrig
): DeductionInput => ({...serialized})
export const serializeDeductionInput = (
  deserialized: DeductionInput
): DeductionInputOrig => ({...deserialized})
export interface DocumentFileInput {
  id?: string | null
  name: string
  fileKey: string
  applicationId?: string | null
  personId?: string | null
  accountId?: string | null
  zipFileId?: string | null
}
/**
 * Attributes for creating a new document
 */

export const deserializeDocumentFileInput = (
  serialized: DocumentFileInputOrig
): DocumentFileInput => ({...serialized})
export const serializeDocumentFileInput = (
  deserialized: DocumentFileInput
): DocumentFileInputOrig => ({...deserialized})
export interface DocumentInput {
  id?: string | null
  applicationId: string
  documentType: string
  complete?: boolean | null
  fileIds: string[]
  notes?: string | null
  personId?: string | null
}
export const deserializeDocumentInput = (
  serialized: DocumentInputOrig
): DocumentInput => ({...serialized})
export const serializeDocumentInput = (
  deserialized: DocumentInput
): DocumentInputOrig => ({...deserialized})
export interface EditableFileInput {
  personId: string
  fileKey: string
  documentName: string
  extension?: string | null
  allowSigning?: boolean | null
  status?: string | null
}
/**
 * Attributes for creating new eligibility determination records
 */

export const deserializeEditableFileInput = (
  serialized: EditableFileInputOrig
): EditableFileInput => ({...serialized})
export const serializeEditableFileInput = (
  deserialized: EditableFileInput
): EditableFileInputOrig => ({...deserialized})
export interface EligibilityDeterminationInput {
  benefit: string
  triageId?: string | null
  applicationId?: string | null
  personId: string
  status: string
  reasonOrInfo?: string | null
  secondaryInfo?: string | null
  householdSizeDescription?: string | null
  month?: Date | null
  numMonths?: number | null
  additionalData?: AdditionalData | null
}
/**
 * Attributes for creating or updating an income source
 */

export const deserializeEligibilityDeterminationInput = (
  serialized: EligibilityDeterminationInputOrig
): EligibilityDeterminationInput => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeEligibilityDeterminationInput = (
  deserialized: EligibilityDeterminationInput
): EligibilityDeterminationInputOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface IncomeSourceInput {
  id?: string | null
  incomeType?: string | null
  employerName?: string | null
  employerAddressStreet?: string | null
  employerAddressCity?: string | null
  employerAddressState?: string | null
  employerAddressZip?: string | null
  employerPhone?: string | null
  employerInsurance?: boolean | null
  startMonth?: string | null
  startYear?: string | null
  endMonth?: string | null
  endYear?: string | null
  fullPartTime?: string | null
  amount?: number | null
  payFrequency?: string | null
  usedForEsign?: boolean | null
  esignSessionId?: string | null
  esignSessionStatus?: string | null
  proofOfIncome?: boolean | null
  noProofOfIncomeReason?: string | null
}
/**
 * Attributes for creating a new person or updating an existing person
 */

export const deserializeIncomeSourceInput = (
  serialized: IncomeSourceInputOrig
): IncomeSourceInput => ({...serialized})
export const serializeIncomeSourceInput = (
  deserialized: IncomeSourceInput
): IncomeSourceInputOrig => ({...deserialized})
export interface PersonInput {
  id?: string | null
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  suffix?: string | null
  preferredName?: string | null
  dob?: Date | null
  gender?: string | null
  ssn?: string | null
  phoneNumbers: PhoneNumberInput[]
  homeAddressStreet?: string | null
  homeAddressCity?: string | null
  homeAddressState?: string | null
  homeAddressZip?: string | null
  homeAddressComment?: string | null
  mailingAddressStreet?: string | null
  mailingAddressCity?: string | null
  mailingAddressState?: string | null
  mailingAddressZip?: string | null
  mailingAddressComment?: string | null
  email?: string | null
  emailComment?: string | null
  preferredLanguage?: string | null
  hospitalPatientId?: string | null
}
/**
 * Attributes for creating or updating a phone number
 */

export const deserializePersonInput = (
  serialized: PersonInputOrig
): PersonInput => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(deserializePhoneNumberInput),
})
export const serializePersonInput = (
  deserialized: PersonInput
): PersonInputOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(serializePhoneNumberInput),
})
export interface PhoneNumberInput {
  id?: string | null
  number: string
  language?: string | null
  comment?: string | null
  usedInApp?: boolean | null
  usedForEsign?: boolean | null
  esignSessionId?: string | null
  esignSessionStatus?: string | null
}
/**
 * Attributes for creating or updating a related person
 */

export const deserializePhoneNumberInput = (
  serialized: PhoneNumberInputOrig
): PhoneNumberInput => ({...serialized})
export const serializePhoneNumberInput = (
  deserialized: PhoneNumberInput
): PhoneNumberInputOrig => ({...deserialized})
export interface RelatedPersonInput {
  id?: string | null
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  suffix?: string | null
  preferredName?: string | null
  dob?: Date | null
  gender?: string | null
  ssn?: string | null
  phoneNumbers: PhoneNumberInput[]
  homeAddressStreet?: string | null
  homeAddressCity?: string | null
  homeAddressState?: string | null
  homeAddressZip?: string | null
  homeAddressComment?: string | null
  mailingAddressStreet?: string | null
  mailingAddressCity?: string | null
  mailingAddressState?: string | null
  mailingAddressZip?: string | null
  mailingAddressComment?: string | null
  email?: string | null
  emailComment?: string | null
  preferredLanguage?: string | null
  hospitalPatientId?: string | null
  livesInNj?: boolean | null
  hasInsurance?: boolean | null
  ciStatus?: string | null
  fiveYearsInUs?: boolean | null
  specialCategory?: boolean | null
  ciDocumentType?: string | null
  pregnant?: boolean | null
  maritalStatus?: string | null
  spouseLiveWithMe?: boolean | null
  expectedChild?: number | null
  taxFilingStatus?: string | null
  claimedBySomeoneOtherThanParents?: boolean | null
  livingWithParentsWhoDoNotFileJointly?: boolean | null
  estTaxDependentCount?: number | null
  taxDependentInclSpouseLiveWithClient?: boolean | null
  estParentLiveWithClient?: number | null
  estChildren19LiveWithClient?: number | null
  estMinor19SiblingLiveWithClient?: number | null
  estHouseholdIncome?: number | null
  incomeSources: IncomeSourceInput[]
  deductions: DeductionInput[]
  currentInsuranceName?: string | null
  currentInsurancePolicyNo?: string | null
  insuranceStartdate?: Date | null
  njResidencyDoc?: string | null
  ciDocumentName?: string | null
  dateOfEntry?: Date | null
  uscisNo?: string | null
  prCardNo?: string | null
  dueDate?: Date | null
  spousePregnant?: boolean | null
  isStudent?: boolean | null
  dependentOfTaxFilerId?: string | null
  changeJobInLast6Mon?: string | null
  income?: boolean | null
  whoSupportWithoutIncome?: string | null
  othersSupportWithoutIncome?: string | null
  selfSupportWithoutIncome?: string | null
  insuranceLast3Months?: boolean | null
  wantsCoverage?: boolean | null
  alaskanNativeamerican?: boolean | null
  fosterCareAfter18?: boolean | null
  identityDocument?: string | null
  desiredMco?: string | null
  pcp?: string | null
  childrenPcp?: string | null
  children18LiveWithClient?: number | null
  minor18SiblingLiveWithClient?: number | null
  assets: AssetInput[]
  pendingUsCitizenship?: boolean | null
  fieldsUpdatedAt?: DeserializedFieldsUpdatedAt | null
  livedInNjSinceMonth?: string | null
  livedInNjSinceYear?: string | null
  hasDeduction?: boolean | null
  hasAsset?: boolean | null
  noIncomeSinceMonth?: string | null
  noIncomeSinceYear?: string | null
  noAssetSinceMonth?: string | null
  noAssetSinceYear?: string | null
  homelessSinceMonth?: string | null
  homelessSinceYear?: string | null
  supporterRelationship?: string | null
  supportSinceMonth?: string | null
  supportSinceYear?: string | null
  supportType?: string[] | null
  supporterAddressStreet?: string | null
  supporterAddressCity?: string | null
  supporterAddressState?: string | null
  supporterAddressZip?: string | null
  supporterPhone?: string | null
  othersAttestationWho?: string | null
  othersAttestationRelationship?: string | null
  othersAttestationPhone?: string | null
  addressSinceMonth?: string | null
  addressSinceYear?: string | null
  race?: string | null
  currentInsuranceType?: string | null
  currentInsuranceIncludesDental?: boolean | null
  noIdentityDocReason?: string | null
  ownNonresidentProperty?: boolean | null
  nonresidentPropertyMailingAddressStreet?: string | null
  nonresidentPropertyMailingAddressCity?: string | null
  nonresidentPropertyMailingAddressState?: string | null
  nonresidentPropertyMailingAddressZip?: string | null
  separatedSinceMonth?: string | null
  separatedSinceYear?: string | null
  dontLiveWithSpouse?: boolean | null
  noJointAssetsWithSpouse?: boolean | null
  noFinancialSupportFromSpouse?: boolean | null
  notFiledJointlySince?: string | null
  reasonNotFiling?: string | null
  lastKnownAddressStreet?: string | null
  lastKnownAddressCity?: string | null
  lastKnownAddressState?: string | null
  lastKnownAddressZip?: string | null
}
/**
 * Attributes for creating or updating a relationship
 */

export const deserializeRelatedPersonInput = (
  serialized: RelatedPersonInputOrig
): RelatedPersonInput => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(deserializePhoneNumberInput),
  incomeSources: serialized.incomeSources.map(deserializeIncomeSourceInput),
  deductions: serialized.deductions.map(deserializeDeductionInput),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  assets: serialized.assets.map(deserializeAssetInput),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeRelatedPersonInput = (
  deserialized: RelatedPersonInput
): RelatedPersonInputOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(serializePhoneNumberInput),
  incomeSources: deserialized.incomeSources.map(serializeIncomeSourceInput),
  deductions: deserialized.deductions.map(serializeDeductionInput),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  assets: deserialized.assets.map(serializeAssetInput),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface RelationshipInput {
  id?: string | null
  relationshipType?: string | null
  otherPerson: RelatedPersonInput
  liveTogether?: boolean | null
  fieldsUpdatedAt?: DeserializedFieldsUpdatedAt | null
}
export const deserializeRelationshipInput = (
  serialized: RelationshipInputOrig
): RelationshipInput => ({
  ...serialized,
  otherPerson: deserializeRelatedPersonInput(serialized.otherPerson),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeRelationshipInput = (
  deserialized: RelationshipInput
): RelationshipInputOrig => ({
  ...deserialized,
  otherPerson: serializeRelatedPersonInput(deserialized.otherPerson),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface TaskInput {
  status: string
  personId: string
  taskType: string
  note?: string | null
  assignedToId: string
  durationMin?: number | null
  dueDate: Date
}
export const deserializeTaskInput = (serialized: TaskInputOrig): TaskInput => ({
  ...serialized,
  dueDate: parseDate(serialized.dueDate),
})
export const serializeTaskInput = (deserialized: TaskInput): TaskInputOrig => ({
  ...deserialized,
  dueDate: formatISODate(deserialized.dueDate),
})
export interface TriageInput {
  id: string
  status?: string | null
  closeReason?: string | null
  closeComment?: string | null
  assignedToId?: string | null
}
/**
 * Attributes for updating an existing account
 */

export const deserializeTriageInput = (
  serialized: TriageInputOrig
): TriageInput => ({...serialized})
export const serializeTriageInput = (
  deserialized: TriageInput
): TriageInputOrig => ({...deserialized})
export interface UpdateAccountInput {
  id: string
  personId?: string | null
  hospitalAccountId: string
  dateOfService: Date
  timeOfService?: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location?: string | null
  insuranceType: string
  notes?: string | null
  accountAmount?: number | null
  njmmisCheck?: string | null
  previousInsurance?: string | null
  invoiceMonth?: string | null
  invoiceYear?: string | null
  dischargeDate?: Date | null
  isScheduled: boolean
}
export const deserializeUpdateAccountInput = (
  serialized: UpdateAccountInputOrig
): UpdateAccountInput => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdateAccountInput = (
  deserialized: UpdateAccountInput
): UpdateAccountInputOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdateAccountStatusInput {
  id: string
  status: string
}
export const deserializeUpdateAccountStatusInput = (
  serialized: UpdateAccountStatusInputOrig
): UpdateAccountStatusInput => ({...serialized})
export const serializeUpdateAccountStatusInput = (
  deserialized: UpdateAccountStatusInput
): UpdateAccountStatusInputOrig => ({...deserialized})
export interface UpdateApplicationInput {
  id: string
  primaryPointOfContactId?: string | null
  submitDate?: Date | null
  applicationType?: string | null
  submitMethod?: string | null
  location?: string | null
  countyName?: string | null
  darSubmitDate?: Date | null
  assignedToId?: string | null
  notes?: string | null
  confirmationNumber?: string | null
  policyId?: string | null
  initialDateOfService?: Date | null
  accountNumber?: string | null
  dateOfApplication?: Date | null
  requestedDateOfApplication?: Date | null
  coveragePeriod?: number | null
  receivedDate?: Date | null
  mcdType?: string | null
  charityCareType?: string | null
  slideType?: string | null
  mcdSubtype?: string | null
}
export const deserializeUpdateApplicationInput = (
  serialized: UpdateApplicationInputOrig
): UpdateApplicationInput => ({
  ...serialized,
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  mcdType:
    serialized.mcdType != null
      ? deserializeMcdType(serialized.mcdType)
      : serialized.mcdType,
  slideType:
    serialized.slideType != null
      ? deserializeSlideType(serialized.slideType)
      : serialized.slideType,
  mcdSubtype:
    serialized.mcdSubtype != null
      ? deserializeMcdSubtype(serialized.mcdSubtype)
      : serialized.mcdSubtype,
})
export const serializeUpdateApplicationInput = (
  deserialized: UpdateApplicationInput
): UpdateApplicationInputOrig => ({
  ...deserialized,
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  mcdType:
    deserialized.mcdType != null
      ? serializeMcdType(deserialized.mcdType)
      : deserialized.mcdType,
  slideType:
    deserialized.slideType != null
      ? serializeSlideType(deserialized.slideType)
      : deserialized.slideType,
  mcdSubtype:
    deserialized.mcdSubtype != null
      ? serializeMcdSubtype(deserialized.mcdSubtype)
      : deserialized.mcdSubtype,
})
export interface UpdateApplicationStatusInput {
  id: string
  status: string
}
export const deserializeUpdateApplicationStatusInput = (
  serialized: UpdateApplicationStatusInputOrig
): UpdateApplicationStatusInput => ({...serialized})
export const serializeUpdateApplicationStatusInput = (
  deserialized: UpdateApplicationStatusInput
): UpdateApplicationStatusInputOrig => ({...deserialized})
export interface UpdateDocumentInput {
  id: string
  complete?: boolean | null
  fileIds: string[]
  notes?: string | null
  documentType: string
}
export const deserializeUpdateDocumentInput = (
  serialized: UpdateDocumentInputOrig
): UpdateDocumentInput => ({...serialized})
export const serializeUpdateDocumentInput = (
  deserialized: UpdateDocumentInput
): UpdateDocumentInputOrig => ({...deserialized})
export interface UpdateEditableFileInput {
  id: string
  fileKey: string
  extension?: string | null
  status?: EditableFileStatus | null
}
export const deserializeUpdateEditableFileInput = (
  serialized: UpdateEditableFileInputOrig
): UpdateEditableFileInput => ({...serialized})
export const serializeUpdateEditableFileInput = (
  deserialized: UpdateEditableFileInput
): UpdateEditableFileInputOrig => ({...deserialized})
export interface UpdateEsignSessionInput {
  id: string
  unreviewed?: boolean | null
}
/**
 * Attributes for creating a new person or updating an existing person
 */

export const deserializeUpdateEsignSessionInput = (
  serialized: UpdateEsignSessionInputOrig
): UpdateEsignSessionInput => ({...serialized})
export const serializeUpdateEsignSessionInput = (
  deserialized: UpdateEsignSessionInput
): UpdateEsignSessionInputOrig => ({...deserialized})
export interface UpdatePersonInput {
  id?: string | null
  firstName?: string | null
  middleName?: string | null
  lastName?: string | null
  suffix?: string | null
  preferredName?: string | null
  dob?: Date | null
  gender?: string | null
  ssn?: string | null
  phoneNumbers: PhoneNumberInput[]
  homeAddressStreet?: string | null
  homeAddressCity?: string | null
  homeAddressState?: string | null
  homeAddressZip?: string | null
  homeAddressComment?: string | null
  mailingAddressStreet?: string | null
  mailingAddressCity?: string | null
  mailingAddressState?: string | null
  mailingAddressZip?: string | null
  mailingAddressComment?: string | null
  email?: string | null
  emailComment?: string | null
  preferredLanguage?: string | null
  hospitalPatientId?: string | null
  livesInNj?: boolean | null
  hasInsurance?: boolean | null
  ciStatus?: string | null
  fiveYearsInUs?: boolean | null
  specialCategory?: boolean | null
  ciDocumentType?: string | null
  pregnant?: boolean | null
  maritalStatus?: string | null
  spouseLiveWithMe?: boolean | null
  expectedChild?: number | null
  taxFilingStatus?: string | null
  claimedBySomeoneOtherThanParents?: boolean | null
  livingWithParentsWhoDoNotFileJointly?: boolean | null
  estTaxDependentCount?: number | null
  taxDependentInclSpouseLiveWithClient?: boolean | null
  estParentLiveWithClient?: number | null
  estChildren19LiveWithClient?: number | null
  estMinor19SiblingLiveWithClient?: number | null
  estHouseholdIncome?: number | null
  relationships: RelationshipInput[]
  incomeSources: IncomeSourceInput[]
  deductions: DeductionInput[]
  currentInsuranceName?: string | null
  currentInsurancePolicyNo?: string | null
  insuranceStartdate?: Date | null
  njResidencyDoc?: string | null
  ciDocumentName?: string | null
  dateOfEntry?: Date | null
  uscisNo?: string | null
  prCardNo?: string | null
  dueDate?: Date | null
  spousePregnant?: boolean | null
  isStudent?: boolean | null
  dependentOfTaxFilerId?: string | null
  changeJobInLast6Mon?: string | null
  income?: boolean | null
  whoSupportWithoutIncome?: string | null
  othersSupportWithoutIncome?: string | null
  selfSupportWithoutIncome?: string | null
  insuranceLast3Months?: boolean | null
  wantsCoverage?: boolean | null
  alaskanNativeamerican?: boolean | null
  fosterCareAfter18?: boolean | null
  identityDocument?: string | null
  desiredMco?: string | null
  pcp?: string | null
  childrenPcp?: string | null
  note?: string | null
  children18LiveWithClient?: number | null
  minor18SiblingLiveWithClient?: number | null
  assets: AssetInput[]
  pendingUsCitizenship?: boolean | null
  fieldsUpdatedAt?: DeserializedFieldsUpdatedAt | null
  livedInNjSinceMonth?: string | null
  livedInNjSinceYear?: string | null
  hasDeduction?: boolean | null
  hasAsset?: boolean | null
  noIncomeSinceMonth?: string | null
  noIncomeSinceYear?: string | null
  noAssetSinceMonth?: string | null
  noAssetSinceYear?: string | null
  homelessSinceMonth?: string | null
  homelessSinceYear?: string | null
  supporterRelationship?: string | null
  supportSinceMonth?: string | null
  supportSinceYear?: string | null
  supportType?: string[] | null
  supporterAddressStreet?: string | null
  supporterAddressCity?: string | null
  supporterAddressState?: string | null
  supporterAddressZip?: string | null
  supporterPhone?: string | null
  othersAttestationWho?: string | null
  othersAttestationRelationship?: string | null
  othersAttestationPhone?: string | null
  addressSinceMonth?: string | null
  addressSinceYear?: string | null
  race?: string | null
  currentInsuranceType?: string | null
  currentInsuranceIncludesDental?: boolean | null
  noIdentityDocReason?: string | null
  ownNonresidentProperty?: boolean | null
  nonresidentPropertyMailingAddressStreet?: string | null
  nonresidentPropertyMailingAddressCity?: string | null
  nonresidentPropertyMailingAddressState?: string | null
  nonresidentPropertyMailingAddressZip?: string | null
  separatedSinceMonth?: string | null
  separatedSinceYear?: string | null
  dontLiveWithSpouse?: boolean | null
  noJointAssetsWithSpouse?: boolean | null
  noFinancialSupportFromSpouse?: boolean | null
  notFiledJointlySince?: string | null
  reasonNotFiling?: string | null
  lastKnownAddressStreet?: string | null
  lastKnownAddressCity?: string | null
  lastKnownAddressState?: string | null
  lastKnownAddressZip?: string | null
}
export const deserializeUpdatePersonInput = (
  serialized: UpdatePersonInputOrig
): UpdatePersonInput => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(deserializePhoneNumberInput),
  relationships: serialized.relationships.map(deserializeRelationshipInput),
  incomeSources: serialized.incomeSources.map(deserializeIncomeSourceInput),
  deductions: serialized.deductions.map(deserializeDeductionInput),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  assets: serialized.assets.map(deserializeAssetInput),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePersonInput = (
  deserialized: UpdatePersonInput
): UpdatePersonInputOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(serializePhoneNumberInput),
  relationships: deserialized.relationships.map(serializeRelationshipInput),
  incomeSources: deserialized.incomeSources.map(serializeIncomeSourceInput),
  deductions: deserialized.deductions.map(serializeDeductionInput),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  assets: deserialized.assets.map(serializeAssetInput),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdateReadyForAuditInput {
  id: string
  flagContext: string
  readyForAudit: boolean
}
export const deserializeUpdateReadyForAuditInput = (
  serialized: UpdateReadyForAuditInputOrig
): UpdateReadyForAuditInput => ({...serialized})
export const serializeUpdateReadyForAuditInput = (
  deserialized: UpdateReadyForAuditInput
): UpdateReadyForAuditInputOrig => ({...deserialized})
export interface UpdateReceivedWebformInput {
  id: string
  hospitalPatientId?: string | null
  noPersonMatch?: boolean | null
}
export const deserializeUpdateReceivedWebformInput = (
  serialized: UpdateReceivedWebformInputOrig
): UpdateReceivedWebformInput => ({...serialized})
export const serializeUpdateReceivedWebformInput = (
  deserialized: UpdateReceivedWebformInput
): UpdateReceivedWebformInputOrig => ({...deserialized})
export interface UpdateTaskInput {
  id: string
  status?: string | null
  taskType?: string | null
  note?: string | null
  assignedToId?: string | null
  durationMin?: number | null
  dueDate?: Date | null
} //==============================================================
// END Enums and Input Objects
//==============================================================

export const deserializeUpdateTaskInput = (
  serialized: UpdateTaskInputOrig
): UpdateTaskInput => ({
  ...serialized,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
})
export const serializeUpdateTaskInput = (
  deserialized: UpdateTaskInput
): UpdateTaskInputOrig => ({
  ...deserialized,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
})
